import React, { useState, useEffect } from "react"
import { Formik, Field, ErrorMessage } from "formik"
import * as yup from "yup"
import Form from "react-bootstrap/Form"
import FormGroup from "react-bootstrap/FormGroup"
import FormLabel from "react-bootstrap/FormLabel"
import FormControl from "react-bootstrap/FormControl"
import Col from "react-bootstrap/Col"
import InputGroup from "react-bootstrap/InputGroup"
import Button from "react-bootstrap/Button"
import col from "react-bootstrap/Col"

const URI ="https://u5ain-server.netlify.app//.netlify/functions/index"
// const URI = "http://localhost:9000/.netlify/functions/index"

const schema = yup.object({
  name: yup.string("It must be a valid email").required("Name required"),
  email: yup.string().email().required("Email required"),
  student: yup.bool(),
  enterpreneur: yup.bool(),
  selfEmployed: yup.bool(),
  employee: yup.bool(),
  homemaker: yup.bool(),
  others: yup.bool(),
  othersValue: yup.string(),
  selfIdentity: yup
    .bool()
    .when(
      [
        "student",
        "enterpreneur",
        "selfEmployed",
        "employee",
        "homemaker",
        "others",
        "othersValue",
      ],
      {
        is: (
          student,
          enterpreneur,
          selfEmployed,
          employee,
          homemaker,
          others,
          othersValue
        ) =>
          enterpreneur ||
          student ||
          selfEmployed ||
          homemaker ||
          employee ||
          (others && !!othersValue),
        then: yup.bool(),
        otherwise: yup.bool().required("Please select anyone"),
      }
    ),

  organisation: yup.string(),
  contactno: yup
    .number()
    .test(
      "contactno",
      "Contact number must be >3 and <20 ",
      val => val.toString().length < 20 && val.toString().length > 3
    )
    .required(),
  country: yup.string().required("Country name is required"),
  state: yup.string().required("This field is required"),
  pincode: yup.string().required("pincode is required"),
  feedback: yup.string().required("feedback is required"),
})
const initialValues = {
  name: "",
  email: "",
  student: false,
  enterpreneur: false,
  selfEmployed: false,
  employee: false,
  homemaker: false,
  others: false,
  contactno: "",
  organisation: "",
  country: "",
  state: "",
  pincode: "",
  feedback: "",
}
const encode = data => {
  return Object.keys(data)
    .map(key => encodeURIComponent(key) + "=" + encodeURIComponent(data[key]))
    .join("&")
}
const BootStrapForm = () => {
  const [isSubmitting, setSubmitting] = useState(false)
  
  return (
    <Formik
      validationSchema={schema}
      initialValues={initialValues}
      onSubmit={(values, { resetForm }) => {
        var designation = []
        if (values.student === true) {
          designation.push("Student")
        }
        if (values.selfEmployed === true) {
          designation.push("Self Employed")
        }
        if (values.employee === true) {
          designation.push("Employee")
        }
        if (values.enterpreneur === true) {
          designation.push("Enterpreneur")
        }
        if (values.homemaker === true) {
          designation.push("Homemake / Gaurdian")
        }
        if (values.others === true) {
          designation.push(values.othersValue)
        }
        return (fetch(URI, {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify({
            email: values.email,
            name: values.name,
            selfIdentity: designation,
            organisation: values.organisation,
            contactno: values.contactno,
            country: values.country,
            state: values.state,
            pincode: values.pincode,
            feedback: values.feedback,
          }),
        })
          .then(res => {
            console.log(res)
            if (res.status === 200) {
              console.log(res)
              resetForm({ values: "" })
              if (!alert("Feedback Sent!")) {
                window.location.reload()
              }
            } else throw new Error()
          })
          .catch(error => {
            console.log(error)
            if (!alert("Error: Please Try Again!")) {
              window.location.reload()
            }
            setSubmitting(false)
          }))
      }}
    >
      {({
        handleSubmit,
        handleChange,
        handleBlur,
        values,
        touched,
        isValid,
        dirty,
        errors,
        isSubmitting,
      }) => (
        <Form
          name="Feedback Form"
          method="post"
          data-netlify="true"
          noValidate
          onSubmit={handleSubmit}
        >
          <input type="hidden" name="form-name" value="Feedback Form" />
          <Form.Group as={Col}>
            <Form.Label>Email address </Form.Label>
            <Field
              as={FormControl}
              type="email"
              placeholder="Your Email"
              name="email"
              value={values.email}
              onChange={handleChange}
              isInvalid={!!errors.email}
            />
            <FormControl.Feedback type="invalid">
              {errors.email}
            </FormControl.Feedback>
          </Form.Group>

          <Form.Group as={Col}>
            <Form.Label>Name</Form.Label>
            <Field
              as={FormControl}
              type="text"
              placeholder="Your Name"
              name="name"
              value={values.name}
              onChange={handleChange}
              isInvalid={!!errors.name}
            />
            <FormControl.Feedback type="invalid">
              {errors.name}
            </FormControl.Feedback>
          </Form.Group>

          <FormGroup as={col}>
            <Form.Label>Self Identity</Form.Label>
            <Form.Check
              type="checkbox"
              label="student @ school / college"
              name="student"
              onChange={handleChange}
              value={values.student}
              checked={values.student != ""}
              isInvalid={!!errors.selfIdentity}
            />
            <Form.Check
              type="checkbox"
              label="enterpreneur"
              name="enterpreneur"
              onChange={handleChange}
              value={values.enterpreneur}
              checked={values.enterpreneur != ""}
              isInvalid={!!errors.selfIdentity}
            />

            <Form.Check
              type="checkbox"
              label="self employed"
              name="selfEmployed"
              onChange={handleChange}
              value={values.selfEmployed}
              checked={values.selfEmployed != ""}
              isInvalid={!!errors.selfIdentity}
            />
            <Form.Check
              type="checkbox"
              label="employee ( government / private )"
              name="employee"
              onChange={handleChange}
              value={values.employee}
              checked={values.employee != ""}
              isInvalid={!!errors.selfIdentity}
            />
            <Form.Check
              type="checkbox"
              label="homemaker / guardian"
              name="homemaker"
              onChange={handleChange}
              value={values.homemaker}
              checked={values.homemaker != ""}
              isInvalid={!!errors.selfIdentity}
            />
            <Form.Check
              type="checkbox"
              label="others"
              name="others"
              onChange={handleChange}
              value={values.others}
              checked={values.others != ""}
              isInvalid={!!errors.selfIdentity}
            />
            {values.others ? (
              <Field
                as={FormControl}
                type="text"
                placeholder="Your answer"
                name="othersValue"
                value={values.othersValue}
                onChange={handleChange}
                isInvalid={!!errors.SelfIdentity}
              />
            ) : null}
            <FormControl.Feedback type="invalid">
              {errors.SelfIdentity}
            </FormControl.Feedback>
          </FormGroup>

          <Form.Group as={Col}>
            <Form.Label>Organisation name & designation </Form.Label>
            <Field
              as={FormControl}
              type="text"
              name="organisation"
              value={values.organisation}
              onChange={handleChange}
              isInvalid={!!errors.organisation}
            />
            <FormControl.Feedback type="invalid">
              {errors.organisation}
            </FormControl.Feedback>
          </Form.Group>

          <Form.Group as={Col}>
            <Form.Label>Contact Number </Form.Label>
            <Field
              as={FormControl}
              type="text"
              placeholder="Contact No."
              name="contactno"
              value={values.contactno}
              onChange={handleChange}
              isInvalid={!!errors.contactno}
            />
            <FormControl.Feedback type="invalid">
              {errors.contactno}
            </FormControl.Feedback>
          </Form.Group>

          <Form.Group as={Col}>
            <Form.Label>Country</Form.Label>
            <Field
              as={FormControl}
              type="text"
              placeholder="Country"
              name="country"
              value={values.country}
              onChange={handleChange}
              isInvalid={!!errors.country}
            />
            <FormControl.Feedback type="invalid">
              {errors.country}
            </FormControl.Feedback>
          </Form.Group>

          <Form.Group as={Col}>
            <Form.Label>State / Locality / postal address </Form.Label>
            <Field
              as={FormControl}
              type="text"
              name="state"
              value={values.state}
              onChange={handleChange}
              isInvalid={!!errors.state}
            />
            <FormControl.Feedback type="invalid">
              {errors.state}
            </FormControl.Feedback>
          </Form.Group>

          <Form.Group as={Col}>
            <Form.Label>pincode </Form.Label>
            <Field
              as={FormControl}
              type="text"
              name="pincode"
              value={values.pincode}
              onChange={handleChange}
              isInvalid={!!errors.pincode}
            />
            <FormControl.Feedback type="invalid">
              {errors.pincode}
            </FormControl.Feedback>
          </Form.Group>

          <Form.Group as={Col}>
            <Form.Label>feedback / suggestions </Form.Label>
            <Field
              as={FormControl}
              type="text"
              name="feedback"
              value={values.feedback}
              onChange={handleChange}
              isInvalid={!!errors.feedback}
            />
            <FormControl.Feedback type="invalid">
              {errors.feedback}
            </FormControl.Feedback>
          </Form.Group>
          <Form.Group as={Col}>
            <Button type="submit" disabled={!dirty || !isValid}>
              Submit
            </Button>
          </Form.Group>
        </Form>
      )}
    </Formik>
  )
}

export default BootStrapForm
