import React from "react"
import Layout from "../components/Layout/Layout"
import BootStrapForm from "../components/Forms/bootForm"

const Feedback = () => {
  return (
    <Layout>
      <BootStrapForm/>
     

    </Layout>
  )
}

export default Feedback
